
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Form} from 'element-ui';
import wxlogin from 'vue-wxlogin';
import {
  platformConfigs,
  wechatLoginRequests,
  warehouse,
  phoneCodeRequests,
  phoneLoginRequests,
  roles,
  roleMappings,
} from '../../resources';
import {ObjectId} from 'bson';
import {getUserId} from '../../api/publicMethod';
import {WechatLoginRequestStatus} from '@/externals/MaxCI-WechatLoginRequest-v1';
import {PhoneLoginRequestStatus} from '@/externals/MaxCI-PhoneLoginRequest-v1';
import Url from 'url';
import {EJSON} from 'bson';
import {Role} from '@/externals/Core-Role-v1';

// declare const REDIRECT_URL: string;

@Component({
  components: {
    wxlogin,
  },
})
export default class Login extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  };
  private formData = {
    code: '',
    phone: '',
  };
  private rules = {
    phone: [
      {required: true, message: '请输入手机号', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
  };
  private loginFlag = false;
  private codeText = '发送验证码';
  private codeFlag = false;
  private loginType = 'account';
  private appid = '';
  private channelId: ObjectId | null = null; //渠道ID
  private applicationId: ObjectId | null = null; //应用ID
  private scope = 'snsapi_login';
  private redirectUri = '';
  private state = '';
  private userId = '';
  private requestId: ObjectId | null = null;
  private identity = '';

  mounted() {
    // 监听窗口大小
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.onresize = (res: any) => {
      if (res.currentTarget.innerWidth < 767) {
        this.loginType = 'account';
      }
    };
    if (window.innerWidth < 767) {
      this.loginType = 'account';
    }
  }
  @Watch('$route')
  async routechange() {
    const code = this.$route.query.code as string; //获取code
    if (code) {
      //扫码登录
      try {
        if (this.channelId && this.applicationId) {
          warehouse.token = undefined;
          const wechatLogin = await wechatLoginRequests.create(
            [
              {
                spec: {
                  type: '微信代码',
                  code: code,
                  channelId: this.channelId,
                  applicationId: this.applicationId,
                  device: '浏览器',
                },
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (!wechatLogin) {
            throw new Error('');
          }
          const status = wechatLogin[0].status;
          if (status) {
            this.loginTo(wechatLogin[0]._id, 'wechat', status);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        this.$message.error('网络异常，请稍后重试');
        this.$router.push({
          query: {},
        });
      }
    }
  }
  async created() {
    //根据链接判断店铺管理员 平台管理员
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentUrl = Url.parse(window.location.href ?? '', true) as any;
    let applicationIdShop = '';
    let channelIdShop = '';
    let sourceType = '';
    if (currentUrl) {
      if (currentUrl.href.indexOf('applicationId') > -1) {
        applicationIdShop = currentUrl.href.match(
          /applicationId=(\S*)&channelId/,
        )[1];
        localStorage.setItem('financeApplicationId', applicationIdShop);
        channelIdShop = currentUrl.href.match(/&channelId=(\S*)&type/)[1];
        sourceType = currentUrl.href.match(/&type=(\S*)/)[1];
        if (sourceType === 'shop') {
          localStorage.setItem('role', '店铺');
          this.identity = '店铺';
        } else if (sourceType === 'finance') {
          localStorage.setItem('role', '财务');
          this.identity = '财务';
        }
      } else {
        localStorage.setItem('role', '应用');
        this.identity = '应用';
      }
    }

    //获取appid
    try {
      const platformConfig = (await platformConfigs.find(stage => stage)).find(
        () => true,
      );
      if (!platformConfig) {
        throw new Error('');
      }
      if (platformConfig.spec) {
        this.appid = platformConfig.spec.channels[0].wechatAppId;
        //店铺管理员  应用管理员
        if (this.identity === '店铺' || this.identity === '财务') {
          this.channelId = ObjectId.createFromHexString(channelIdShop);
          this.applicationId = ObjectId.createFromHexString(applicationIdShop);
        } else {
          this.channelId = platformConfig.spec.channels[0].channelId;
          this.applicationId = platformConfig.spec.applicationId;
        }
        this.redirectUri = encodeURIComponent(window.location.href);
        this.$store.state.pConfig = platformConfig?.spec;
        localStorage.setItem(
          'applicationId',
          platformConfig.spec.applicationId.toHexString(),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //发送验证码
  private async sentCode() {
    (this.$refs.formData as Form).validateField('phone', valid => {
      if (valid === '') {
        try {
          if (!this.codeFlag) {
            this.codeFlag = true;
            if (this.applicationId) {
              phoneCodeRequests
                .create(
                  [
                    {
                      spec: {
                        phone: this.formData.phone,
                        applicationId: this.applicationId,
                        device: '浏览器',
                      },
                    },
                  ],
                  {
                    watch: {
                      filter: filter =>
                        filter(
                          f => f('operationType'),
                          e => e.$eq('update'),
                        )(
                          f => f('fullDocument')('status')('phase'),
                          e => e.$exists(true),
                        ),
                    },
                    fullResource: true,
                  },
                )
                .then(res => {
                  if (res[0].status?.phase === '成功') {
                    let time = 60;
                    const timer = setInterval(() => {
                      time--;
                      this.codeText = '已发送' + time + 's';
                      if (time === 0) {
                        this.codeFlag = false;
                        clearInterval(timer);
                        this.codeText = '重新发送';
                      }
                    }, 1000);
                  } else {
                    this.codeFlag = false;
                    this.$message.error(
                      res[0].status?.conditions[0].message ?? '',
                    );
                  }
                });
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.codeFlag = false;
          this.$message.error('网络异常，请稍后重试');
        }
      }
    });
  }
  //绑定手机号
  private async bindPhone() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.loginFlag = true;
          if (this.requestId && this.channelId && this.applicationId) {
            wechatLoginRequests
              .create(
                [
                  {
                    spec: {
                      type: '电话验证码',
                      requestId: this.requestId,
                      phone: this.formData.phone,
                      code: this.formData.code,
                      channelId: this.channelId,
                      applicationId: this.applicationId,
                      device: '浏览器',
                    },
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              )
              .then(res => {
                if (res) {
                  if (res[0].status?.conditions[0].status) {
                    warehouse.token = res[0].status?.accessToken;
                    localStorage.setItem(
                      'refreshToken',
                      res[0].status?.refreshToken ?? '',
                    );
                    localStorage.setItem(
                      'accessToken',
                      res[0].status?.accessToken ?? '',
                    );
                    this.loginJump();
                  } else {
                    this.$message.error(
                      res[0].status?.conditions[0].message ?? '',
                    );
                  }
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.loginFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //手机号登录
  private async phoneLogin() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.loginFlag = true;
          if (this.applicationId) {
            phoneLoginRequests
              .create(
                [
                  {
                    spec: {
                      phone: this.formData.phone,
                      code: this.formData.code,
                      applicationId: this.applicationId,
                      device: '浏览器',
                    },
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              )
              .then(res => {
                if (res) {
                  const status = res[0].status;
                  if (status) {
                    this.loginTo(res[0]._id, 'phone', status);
                  }
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.loginFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  // 登录跳转
  private loginTo(
    id: ObjectId,
    type: string,
    status: WechatLoginRequestStatus | PhoneLoginRequestStatus,
  ) {
    if (status.conditions[0].status) {
      if (type === 'phone') {
        warehouse.token = status?.accessToken;
        localStorage.setItem('refreshToken', status?.refreshToken ?? '');
        localStorage.setItem('accessToken', status?.accessToken ?? '');
        this.loginJump();
      } else {
        if (status.phase === '等待') {
          this.requestId = id;
          this.loginType = 'bindphone';
          // this.$router.push({
          //   path: '/bindphone/' + id.toHexString(),
          //   query: {},
          // });
        } else {
          warehouse.token = status?.accessToken;
          localStorage.setItem('refreshToken', status?.refreshToken ?? '');
          localStorage.setItem('accessToken', status?.accessToken ?? '');
          this.loginJump();
        }
      }
    } else {
      this.$router.push({path: '/login', query: {}});
      this.$message.error(status?.conditions[0].message ?? '');
    }
  }
  private async loginJump() {
    //店铺管理员  应用管理员   店铺财务
    if (this.identity === '店铺' || this.identity === '财务') {
      let role = [] as Array<Role>;
      //查询角色
      if (this.identity === '店铺') {
        role = await roles.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$eq('店铺管理员'),
            ),
          ),
        );
      } else {
        role = await roles.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$eq('店铺财务'),
            ),
          ),
        );
      }

      //查询用户拥有的角色
      const roleMapping = await roleMappings.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('userId'),
            e => e.$eq(getUserId()),
          )(
            f => f('spec')('roleId'),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            e => e.$in(role.map(v => v._id!)),
          ),
        ),
      );
      //判断用户是否有店铺管理员的角色
      if (roleMapping.length > 0) {
        const shopAuthority = roleMapping.map(v => v.spec.scopeId);
        localStorage.setItem('shopAuthority', EJSON.stringify(shopAuthority));
        this.$router.push({
          path: '/application/' + this.applicationId + '/order/index',
        });
      } else {
        this.$message.error('您没有店铺！请联系管理员');
      }
    } else {
      //查询角色
      const role = await roles.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('name'),
            e => e.$eq('平台管理员'),
          ),
        ),
      );
      //查询用户拥有的角色
      const roleMapping = await roleMappings.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('userId'),
            e => e.$eq(getUserId()),
          )(
            f => f('spec')('roleId'),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            e => e.$in(role.map(v => v._id!)),
          ),
        ),
      );
      if (roleMapping.length > 0) {
        localStorage.setItem('roleName', '平台管理员');
      } else {
        localStorage.setItem('roleName', '应用管理员');
      }
      this.$router.push({path: '/home', query: {}});
    }
  }
}
